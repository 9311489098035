import React from "react";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { Document } from "@contentful/rich-text-types";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

export const RichTextStyledRenderer = ({
  content,
  entryId,
  contentType,
  bulletStyling,
  markerStyling,
}: {
  content: Document;
  entryId: string;
  contentType: string;
  bulletStyling?: string;
  markerStyling?: string;
}) => {
  if (!content || !content.content) {
    console.warn(`No rich text content found for entry ID: ${entryId}`);
    return <div>No content available</div>;
  }

  // Check if custom bullet styling should be applied
  const isCustomBullet = bulletStyling === "Green check box";

  // Check if custom marker styling should be applied for OL
  const isCustomMarker = markerStyling === "Green";

  const options = {
    renderNode: {
      // Custom rendering for unordered lists
      [BLOCKS.UL_LIST]: (node: any) => {
        return (
          <ul className="list-none text-primary">
            {node.content.map((listItemNode: any, index: number) => (
              <li
                key={`${entryId}-ul-${index}`}
                className="mb-4 flex items-start"
              >
                {/* Conditionally render custom bullet styling */}
                {isCustomBullet ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="none"
                    viewBox="0 0 22 22"
                    className="mr-2 flex-shrink-0"
                  >
                    <path
                      fill="#97D700"
                      d="m9.367 15.9 8.225-8.225-1.634-1.633-6.591 6.591-3.325-3.325-1.634 1.634L9.367 15.9Zm-6.534 5.6a2.247 2.247 0 0 1-1.648-.685A2.247 2.247 0 0 1 .5 19.167V2.833c0-.641.228-1.19.685-1.648A2.247 2.247 0 0 1 2.833.5h16.334c.641 0 1.19.228 1.648.685.456.457.685 1.007.685 1.648v16.334c0 .641-.229 1.19-.685 1.648a2.247 2.247 0 0 1-1.648.685H2.833Z"
                    />
                  </svg>
                ) : (
                  <span className="mr-2">•</span>
                )}

                <div className="flex-1">
                  {listItemNode.content.map(
                    (childNode: any, childIndex: number) => {
                      if (childNode.nodeType === BLOCKS.PARAGRAPH) {
                        return (
                          <React.Fragment
                            key={`${entryId}-ul-${index}-${childIndex}`}
                          >
                            {childNode.content.map(
                              (textNode: any, textIndex: number) => {
                                if (textNode.nodeType === "text") {
                                  return (
                                    <span
                                      key={`${entryId}-ul-${index}-${childIndex}-${textIndex}`}
                                      className="inline"
                                    >
                                      {textNode.value}
                                    </span>
                                  );
                                }

                                if (textNode.nodeType === INLINES.HYPERLINK) {
                                  return (
                                    <a
                                      key={`${entryId}-ul-${index}-${childIndex}-${textIndex}`}
                                      href={textNode.data.uri}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="font-bold text-black hover:underline focus:underline"
                                    >
                                      {textNode.content.map(
                                        (
                                          hyperlinkTextNode: any,
                                          linkTextIndex: number,
                                        ) => {
                                          if (
                                            hyperlinkTextNode.nodeType ===
                                            "text"
                                          ) {
                                            return (
                                              <span
                                                key={`${entryId}-ul-${index}-${childIndex}-${textIndex}-${linkTextIndex}`}
                                              >
                                                {hyperlinkTextNode.value}
                                              </span>
                                            );
                                          }
                                          return documentToReactComponents(
                                            hyperlinkTextNode,
                                            options,
                                          );
                                        },
                                      )}
                                    </a>
                                  );
                                }

                                return documentToReactComponents(
                                  textNode,
                                  options,
                                );
                              },
                            )}
                          </React.Fragment>
                        );
                      }

                      return documentToReactComponents(childNode, options);
                    },
                  )}
                </div>
              </li>
            ))}
          </ul>
        );
      },

      // Custom rendering for ordered lists
      [BLOCKS.OL_LIST]: (node: any) => (
        <ol
          className={`list-inside list-decimal ${
            isCustomMarker ? "marker:text-[#97D700]" : "list-decimal"
          }`}
        >
          {node.content.map((listItemNode: any, index: number) => (
            <li key={`${entryId}-ol-${index}`} className="mb-4">
              {listItemNode.content.map(
                (childNode: any, childIndex: number) => {
                  if (childNode.nodeType === BLOCKS.PARAGRAPH) {
                    return (
                      <React.Fragment
                        key={`${entryId}-ol-${index}-${childIndex}`}
                      >
                        {childNode.content.map(
                          (textNode: any, textIndex: number) => {
                            if (textNode.nodeType === "text") {
                              return (
                                <span
                                  key={`${entryId}-ol-${index}-${childIndex}-${textIndex}`}
                                  className="flex-1"
                                >
                                  {textNode.value}
                                </span>
                              );
                            }
                            return documentToReactComponents(textNode, options);
                          },
                        )}
                      </React.Fragment>
                    );
                  }
                  return documentToReactComponents(childNode, options);
                },
              )}
            </li>
          ))}
        </ol>
      ),

      // Custom rendering for paragraphs
      [BLOCKS.PARAGRAPH]: (node: any) => (
        <p className="">
          {node.content.map((textNode: any, textIndex: number) => {
            if (textNode.nodeType === "text") {
              return <span key={textIndex}>{textNode.value}</span>;
            }
            return documentToReactComponents(textNode, options);
          })}
        </p>
      ),

      // Custom rendering for hyperlinks
      [INLINES.HYPERLINK]: (node: any) => (
        <a
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold  text-black hover:underline focus:underline"
        >
          {node.content.map((textNode: any, textIndex: number) => {
            if (textNode.nodeType === "text") {
              return <span key={textIndex}>{textNode.value}</span>;
            }
            return documentToReactComponents(textNode, options);
          })}
        </a>
      ),
    },
  };

  return (
    <div className="rich-text-styled w-full">
      {content.content.map((node: any, index: number) => {
        const additionalClass = index !== 0 ? "mt-6" : "";
        return (
          <div key={`${entryId}-content-${index}`} className={additionalClass}>
            {documentToReactComponents(node, options)}
          </div>
        );
      })}
    </div>
  );
};
